import React, { useEffect } from 'react';
import {
    db,
    auth,
    watchUserChanges,
    watchDatosUSer,
    SignInWithEmailAndPassword,
    SignOut,
    WatchThema,
    WatchPreferencias
} from 'services/firebase';
import { darken, lighten } from '@mui/material/styles';
import { MLog } from 'services/FuncionesDev';
import { IniciarCatalogos, CancelarCatalogos } from './funciones';

const UserStateContext = React.createContext();
const UserDispatchContext = React.createContext();

function userReducer(state, action) {
    switch (action.type) {
        case 'END_LOAD':
            MLog.print('datos context', action.datos);
            return { ...state, user: action.user, datos: action.datos, authReady: true, isAuthenticated: true };
        case 'LOGIN_SUCCESS':
            return { ...state, isAuthenticated: true };
        case 'SIGN_OUT_SUCCESS':
            return { ...state, isAuthenticated: false, user: null, datos: [], authReady: true };
        case 'LOGIN_FAILURE':
            return { ...state, isAuthenticated: false, user: null, datos: [], authReady: true };
        case 'SET_TEMA':
            return { ...state, temaActual: action.tema, temaReady: action.temaReady };
        /* -------------------Catalogos --------------------------- */
        case 'CARGAR_FORMA_PAGO':
            return { ...state, uctListaFormaPago: action.uctListaFormaPago };
        case 'CARGAR_PAGARA':
            return { ...state, uctListaPagarA: action.uctListaPagarA };
        case 'CARGAR_CATEGORIA':
            return { ...state, uctListaCategoria: action.uctListaCategoria };
        case 'CARGAR_ESTABLECIMIENTOS':
            return { ...state, uctListaEstablecimientos: action.uctListaEstablecimientos };
        case 'TERMINAR_CATALOGOS':
            CancelarCatalogos();
            return { ...state };
        default: {
            throw new Error(`Unhandled action type: ${action.type}`);
        }
    }
}

function UserProvider({ children }) {
    const [state, dispatch] = React.useReducer(userReducer, {
        isAuthenticated: false,
        user: null, // son los datos del usuario obtenidos de firebase ath
        datos: [],
        authReady: false,
        uctListaFormaPago: [],
        uctListaCategoria: [],
        uctListaPagarA: [],
        uctListaEstablecimientos: []
    });

    useEffect(() => {
        watchUserChanges((user) => {
            if (user) {
                watchDatosUSer(user.id, (datos) => {
                    MLog.print('datos del user cambiaron');
                    console.log('datos del user cambiaron');
                    if (datos.en_linea) {
                        dispatch({ type: 'END_LOAD', user, datos });
                    }
                });
            } else {
                dispatch({ type: 'LOGIN_FAILURE' });
            }
        });
    }, []);

    return (
        <UserStateContext.Provider value={state}>
            <UserDispatchContext.Provider value={dispatch}>{children}</UserDispatchContext.Provider>
        </UserStateContext.Provider>
    );
}

function UseUserState() {
    const context = React.useContext(UserStateContext);
    if (context === undefined) {
        throw new Error('useUserState must be used within a UserProvider');
    }
    return context;
}

function UseUserDispatch() {
    const context = React.useContext(UserDispatchContext);
    if (context === undefined) {
        throw new Error('useUserDispatch must be used within a UserProvider');
    }
    return context;
}

// ###########################################################
function LoginUser(dispatch, correo, password, setError, setErrordetail) {
    MLog.print('login');
    if (!!correo && password) {
        SignInWithEmailAndPassword(auth, correo, password)
            .then((userCredential) => {
                const user = userCredential.user;
                MLog.print('user', user);
                if (user.emailVerified) {
                    MLog.print('Email verificado');
                } else {
                    MLog.print('Email no verificado');
                }
            })
            .catch((error) => {
                // setErrordetail(error.message);
                // setErrordetail("error")
                // auth/user-not-found error.code "el usuario no existe"
                // auth/wrong-password password incorrecto
                // auth/too-many-requests se supero el limite de intentso
                if (error.code === 'auth/user-not-found') {
                    setErrordetail({ submit: 'El usuario ingresado no es valido' });
                } else if (error.code === 'auth/wrong-password') {
                    setErrordetail({ submit: 'credenciales incorrectas' });
                } else if (error.code === 'auth/too-many-requests') {
                    setErrordetail({ submit: 'Se supero el limite de intentos, por favor intente mas tarde' });
                } else {
                    setErrordetail({ submit: 'Ocurrio un error, por favor intente de nuevo' });
                }
                setError({ success: false });

                // console.error('Error signing in with password and email', error.message);
                // console.error('Error codigo error', error.code);
            });
    } else {
        MLog.print('datos incompletos');
    }
}

function Logaout(dispatch, history) {
    MLog.print('logOut', auth);
    SignOut(auth)
        .then(() => {
            dispatch({ type: 'SIGN_OUT_SUCCESS' });
            MLog.print('singOut correcto');
        })
        .catch((error) => {
            MLog.print('error logOut');
        });
}

function UserForgetPassword(login, history, setIsLoading, setError, setErrordetail, setSuccsesForget) {
    MLog.print('userForgetPassword');
}

function UserNewPassword(password, setIsLoading, setError, setErrordetail, setSuccsesNewPassword) {
    MLog.print('New password');
}

/* ------------------------------------------Cargar catalogos--------------------------------------------- */

function UCTCargarFormaPago(dispatch, lista) {
    dispatch({ type: 'CARGAR_FORMA_PAGO', uctListaFormaPago: lista });
}

function UCTCargarPagarA(dispatch, lista) {
    dispatch({ type: 'CARGAR_PAGARA', uctListaPagarA: lista });
}

function UCTTerminarCatalogos(dispatch) {
    dispatch({ type: 'TERMINAR_CATALOGOS' });
}

export { UserProvider, UseUserState, UseUserDispatch, LoginUser, Logaout, UserForgetPassword, UserNewPassword };
export { UCTCargarFormaPago, UCTCargarPagarA, UCTTerminarCatalogos };

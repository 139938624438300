// eslint-disable-next-line import/no-unresolved
import { initializeApp, getApp } from 'firebase/app';
import { getAuth, onAuthStateChanged, signInWithEmailAndPassword, signOut } from 'firebase/auth';
import {
    getDatabase,
    onValue,
    set,
    push,
    update,
    onDisconnect,
    ref,
    serverTimestamp,
    orderByChild,
    equalTo,
    query,
    limitToFirst,
    limitToLast,
    off,
    onChildChanged,
    onChildAdded,
    onChildRemoved,
    startAfter
} from 'firebase/database';
import { getStorage, ref as refStorage, uploadBytesResumable, getDownloadURL, deleteObject } from 'firebase/storage';
import { getMessaging } from 'firebase/messaging';
import { onBackgroundMessage } from 'firebase/messaging/sw';
import { getFunctions, httpsCallable, connectFunctionsEmulator } from 'firebase/functions';

const config = {
    apiKey: 'AIzaSyB5xqScjVwfSHUzrpYO9Cv6r1oRhB10MRg',
    authDomain: 'guestflow-6587e.firebaseapp.com',
    databaseURL: 'https://guestflow-6587e-default-rtdb.firebaseio.com',
    projectId: 'guestflow-6587e',
    storageBucket: 'guestflow-6587e.appspot.com',
    messagingSenderId: '847565276567',
    appId: '1:847565276567:web:91b6afc854e63f9aa2b0d2'
};

const app = initializeApp(config);

// autenticacion
export const auth = getAuth(app);
export const SignInWithEmailAndPassword = signInWithEmailAndPassword;
export const OnAuthStateChanged = onAuthStateChanged;
export const SignOut = signOut;

// base de datos
export const Basedatos = getDatabase(app);
export const OnValue = onValue;
export const Setdb = set;
export const Updatedb = update;
export const Push = push;
export const OnDisconnect = onDisconnect;
export const Ref = ref;
export const Timestamp = serverTimestamp;
export const OrderByChild = orderByChild;
export const EqualTo = equalTo;
export const Query = query;
export const LimitToFirst = limitToFirst;
export const LimitToLast = limitToLast;
export const Off = off;
export const OnChildChanged = onChildChanged;
export const OnChildAdded = onChildAdded;
export const OnChildRemoved = onChildRemoved;
export const StartAfter = startAfter;

// storage
export const GetStorage = getStorage;
export const RefStorage = refStorage;
export const UploadBytesResumable = uploadBytesResumable;
export const GetDownloadURL = getDownloadURL;
export const DeleteObject = deleteObject;
export const Messaging = getMessaging(app);
export const OnBackgroundMessage = onBackgroundMessage;

// funciones
export const Functions = getFunctions();
export const HttpsCallable = httpsCallable;

const functions = getFunctions(getApp());
// connectFunctionsEmulator(functions, 'localhost', 5001);

// notificaciones
// const messaging = getMessaging(firebaseApp);

// export const fechaHora = getDatabase(app);
//   funciones_fb.useFunctionsEmulator("http://localhost:5001");
/* export const funcionesFb = firebase.functions();
export const auth = firebase.auth();
export const db = firebase.database();
export const authPersistencia = firebase.auth.Auth.Persistence.LOCAL;
export const storage = firebase.storage();
export const constStorage = firebase.storage.TaskState;
export const fechaHora = firebase;
*/

const config = {
    // basename: only at build time to set, and Don't add '/' at end off BASENAME for breadcrumbs, also Don't put only '/' use blank('') instead,
    // like '/berry-material-react/react/default'
    basename: '',
    defaultPath: '/dashboard/default',
    fontFamily: `'Lato', sans-serif`,
    borderRadius: 12,
    // server: 'http://localhost:4000'
    server: 'https://api.paxit.com.gt'
};

export default config;

import { db } from 'services/firebase';
import { MLog } from 'services/FuncionesDev';

export function IniciarCatalogos(dispatch) {
    db.read.on(`formasPago`, (snap) => {
        const formasPago = [];
        snap.forEach((item, i) => {
            formasPago.push({ ...item.val(), id: item.key });
        });
        MLog.print('formasPago', formasPago);
        dispatch({ type: 'CARGAR_FORMA_PAGO', uctListaFormaPago: formasPago });
    });

    db.read.on(`pagarA`, (snap) => {
        const pagarA = [];
        snap.forEach((item, i) => {
            pagarA.push({ ...item.val(), id: item.key });
        });
        MLog.print('pagarA', pagarA);
        dispatch({ type: 'CARGAR_PAGARA', uctListaPagarA: pagarA });
    });

    db.read.on(`categoria`, (snap) => {
        const pagarA = [];
        snap.forEach((item, i) => {
            pagarA.push({ ...item.val(), id: item.key });
        });
        MLog.print('categoria', pagarA);
        dispatch({ type: 'CARGAR_CATEGORIA', uctListaCategoria: pagarA });
    });

    db.read.on(`estrablecimientos`, (snap) => {
        const pagarA = [];
        snap.forEach((item, i) => {
            pagarA.push({ ...item.val(), id: item.key });
        });
        MLog.print('estrablecimientos', pagarA);
        dispatch({ type: 'CARGAR_ESTABLECIMIENTOS', uctListaEstablecimientos: pagarA });
    });
}

export function CancelarCatalogos() {
    db.off(db.ref(`formasPago`));
    db.off(db.ref(`pagarA`));
    db.off(db.ref(`categoria`));
    db.off(db.ref(`estrablecimientos`));
}

export function GuardarCatalogo(url, item) {
    const id = db.push(url, item);
}
